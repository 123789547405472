<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- INFO -->
    <base-material-card
      color="warning"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-calendar-month</v-icon> График отпусков
        </div>
      </template>
      <template #after-heading>
        <v-switch
          v-model="showFilter"
          color="success"
          label="Фильтр"
          class="mr-3 pt-0"
          dense
        />
        <v-btn
          color="info"
          default
          rounded
          class="mt-2"
          @click.stop="openAdd"
        >
          <v-icon class="mr-2">
            mdi-calendar-plus
          </v-icon>
          Добавить
        </v-btn>
      </template>
      <v-card-text>
        <v-row
          v-if="showFilter"
          no-gutters
          justify="space-between"
        >
          <v-select
            v-model="yearFrom"
            class="mx-2"
            :items="years"
            :label="yearRange?'C':'ГОД'"
            prepend-icon="mdi-calendar-text"
            dense
            outlined
            :attach="true"
            @input="update({from:yearFrom,range:yearRange,to:yearTo})"
          />
          <v-select
            v-if="yearRange"
            v-model="yearTo"
            class="mx-2"
            :items="years"
            label="ПО"
            dense
            outlined
            @input="update({from:yearFrom,range:yearRange,to:yearTo})"
          />
          <v-checkbox
            v-model="yearRange"
            class="mt-1 mx-2"
            label="Диапозон"
          />
          <v-autocomplete
            v-if="haveManyEmps"
            v-model="EmpFilter"
            :items="VacEmployees"
            item-text="fioempid"
            item-value="empid"
            label="Сотрудник"
            return-object
            dense
            outlined
          >
            <!--template slot="prepend">
              <v-icon
                v-on:click.stop.prevent="addVendor"
                left
              >
                mdi-account-plus-outline
              </v-icon>
            </template-->
          </v-autocomplete>
          <v-select
            v-if="haveManyDeps"
            v-model="DirectionFilter"
            class="mx-2"
            :items="VacEmployees"
            item-text="departmenttitle"
            item-value="departmenttitle"
            label="Отдел"
            return-object
            dense
            outlined
          />
          <v-select
            v-model="StatusFilter"
            class="mx-2"
            :items="StatusItems"
            item-text="text"
            item-value="status"
            label="Статус"
            return-object
            dense
            outlined
          />
          <v-tooltip
            v-if="filterClear"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                fab
                small
                v-on="on"
                @click="clearFilter"
              >
                <v-icon>mdi-filter-remove-outline</v-icon>
              </v-btn>
            </template>
            <span>Очистить фильтр</span>
          </v-tooltip>
        </v-row>
        <v-data-table
          v-model="selectedVac"
          :headers="headersVac"
          :items="VacDate"
          class="elevation-1 pa-0"
          item-key="StafferVacationID"
          :loading="tableLoading"
          no-data-text="нет данных"
          :search="search"
          :footer-props="{itemsPerPageText:'Строк на странице:'}"
        >
          <template #item="{ item }">
            <tr v-if="item.datetill">
              <td style="width:20%">
                {{ item?item.empfio:'' }}
              </td>
              <td width="20%">
                {{ item.datefroms }} - {{ item.datetills }}
              </td>
              <td width="20%">
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <span v-on="on">
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 130px;"
                      >
                        {{ item.departmenttitle }}</span>
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 130px;"
                      >/{{ item.posttitle }}</span>
                    </span>
                  </template>
                  <span> {{ item.departmenttitle }}/{{ item.posttitle }}</span>
                </v-tooltip>
              </td>
              <td width="10%">
                {{ item.datetill-item.datefrom+1 }}
              </td>
              <td width="10%">
                <v-chip
                  class="ma-2"
                  small
                  :color="statusCase(item.state).color"
                  :class="item.state==0?'d-none':''"
                >
                  {{ statusCase(item.state).text }}
                </v-chip>
              </td>
              <td width="30%">
                <span
                  v-for="(action, i) in actions"
                  :key="'action-'+i"
                >
                  <v-tooltip
                    v-if="showButton(item, action.action)"
                    :key="i"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-btn
                        class="px-2 ml-1"
                        :color="action.color"
                        min-width="0"
                        small
                        v-on="on"
                        @click="datesAction(item, action.action)"
                      >
                        <v-icon
                          small
                          v-text="action.icon"
                        />
                      </v-btn>
                    </template>
                    <span>{{ action.tooltip }}</span>
                  </v-tooltip>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <!-- ОКНО ДОБОВЛЕНИЯ ОТПУСКОВ (START) -->

    <v-dialog
      v-model="addpopup"
      max-width="500px"
    >
      <v-card>
        <v-card-title />
        <v-card-text>
          <v-container>
            <div>
              <emp-select
                :disablebtn="VacEmployees.length == 1"
                class="mb-3"
                :emp-list="VacEmployees"
              />
            </div>
            <v-row>
              <v-date-picker
                v-model="dates"
                range
                :landscape="$vuetify.breakpoint.name != 'xs'"
                locale="ru"
                :first-day-of-week="1"
                :selected-items-text="dates?dateDM(dates) + '\n ' + dateMinusText(dates) + ': ' + dateMinus(dates):' '"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Отмена
          </v-btn>
          <v-btn
            :disabled="!(dateMinus(dates)&&(selectedEmp[0]||VacEmployees.length == 1))"
            color="blue darken-1"
            text
            :loading="loadingBtn"
            @click="saveVac"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ОКНО ДОБОВЛЕНИЯ ОТПУСКОВ (END) -->

    <!-- ОКНО УДАЛЕНИЯ/ПОДТВЕРЖДЕНИЯ/ОТКЛАНЕНИЯ (START) -->
    <v-dialog
      v-model="actionAgree.show"
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="text-h5 text-uppercase"
        >
          <span style="font-size: large">{{ actionAgree.title }}</span>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="actionAgree.show = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col cols="12">
              {{ actionAgree.body }}
            </v-col>
            <v-col
              v-if="actionAgree.inputLabel"
              cols="12"
            >
              <v-text-field
                v-model="actionAgree.inputValue"
                :label="actionAgree.inputLabel"
                class="ml-4"
              />
            </v-col>
          </v-row>
          <span>
            <v-btn
              class="mt-6"
              color="error"
              depressed
              default
              rounded
              @click="actionAgree.show = false"
            >
              нет
            </v-btn>
            <v-btn
              class="mt-6"
              color="success"
              depressed
              default
              rounded
              :disabled="actionAgree.inputLabel&&(actionAgree.inputValue.length<3)"
              :loading="actionAgree.loading"
              @click="actionAgreeSubmit(actionAgree)"
            >
              да
            </v-btn>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ОКНО УДАЛЕНИЯ/ПОДТВЕРЖДЕНИЯ/ОТКЛАНЕНИЯ (END) -->
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EmpSelect from '../components/empSelect'
  const subs = []

  export default {
    name: 'Vacantions',
    components: { EmpSelect },
    data () {
      return {
        landscape: true,
        loadingBtn: false,
        tableEmpLoading: false,
        searchEmps: '',
        empSelectDialog: false,
        accountSelect: null,
        actionAgreeValid: false,
        search: '',
        actionAgree: {
          loading: false,
          type: null,
          show: false,
          title: '',
          body: '',
          description: '',
          inputLabel: '',
          inputValue: '',
          value: {},
        },
        StatusItems: [
          { status: 2, text: 'утвержден' },
          { status: 1, text: 'согласован' },
          { status: -1, text: 'отклонен' },
          { status: 0, text: 'создан' },
        ],
        years: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
        empList: [],
        vacList: [],
        showFilter: false,
        dates: [],
        selectedVac: [],
        actions: [
          {
            color: 'success',
            icon: 'mdi-check',
            tooltip: 'согласовать',
            action: 'approve',
          },
          {
            color: 'success',
            icon: 'mdi-check',
            tooltip: 'утвердить',
            action: 'submit',
          },
          {
            color: 'warning',
            icon: 'mdi-diameter-variant',
            tooltip: 'отклонить',
            action: 'dismiss',
          },
          {
            color: 'error',
            icon: 'mdi-close',
            tooltip: 'удалить',
            action: 'del',
          },
          {
            color: 'default',
            icon: 'mdi-history',
            tooltip: 'история',
            action: 'history',
          },
        ],
        tableLoading: true,
        addpopup: false,
        filter: '',
        yearFrom: null,
        yearTo: null,
        yearRange: false,
        EmpFilter: {},
        DirectionFilter: {},
        StatusFilter: { status: null, text: '' },
      }
    },
    computed: {
      ...mapGetters({
        empid: 'empid',
        VacEmployees: 'VacEmployees',
        VacDate: 'VacDate',
        monthNames: 'monthNames',
        isAuthenticated: 'isAuthenticated',
      }),
      selectedEmp: {
        get: function () {
          return this.$store.getters.selectedEmp
        },
        set: function (newValue) {
          this.$store.dispatch('setSelectedEmp', newValue)
        },
      },
      filterClear () {
        return Object.keys(this.DirectionFilter).length || Object.keys(this.EmpFilter).length || this.StatusFilter.status != null
      },
      haveManyEmps () {
        return this.VacEmployees.length > 1
      },
      haveManyDeps () {
        return this.VacEmployees.length > 1
      },
      headersVac () {
        return [
          {
            text: 'ФИО',
            align: 'left',
            sortable: true,
          },
          {
            text: 'Даты',
            value: 'datefroms',
            sortable: false,
          },
          {
            text: 'Отдел/Должность',
            sortable: false,
            value: 'departmenttitle',
            filter: value => {
              if (!Object.keys(this.DirectionFilter).length) return true
              return value == this.DirectionFilter.departmenttitle
            },
          },
          {
            text: 'дней',
            value: 'days',
            sortable: false,
          },
          {
            text: 'статус',
            align: 'center',
            value: 'state',
            sortable: false,
            filter: value => {
              if (this.StatusFilter.status === null) return true
              return value == parseInt(this.StatusFilter.status)
            },
          },
          {
            text: ' ',
            sortable: false,
            value: 'EmpID',
            filter: value => {
              if (!Object.keys(this.EmpFilter).length) return true
              return value == this.EmpFilter.EmpID
            },
          },
        ]
      },
    },
    beforeDestroy () {
      subs.forEach(unsub => unsub())
    },
    created () {
      let routeCheck = this.$store.getters.lastRoute
      routeCheck = routeCheck || this.$router.currentRoute.query
      console.log(routeCheck)
      this.$store.commit('setVacDate', [])
      const d = new Date()
      this.yearFrom = d.getFullYear().toString()
      const dateFrom = (d.getFullYear()) + '-1-1'
      const dateTo = (d.getFullYear()) + '-12-31'
      var toSend = { dateTo: dateTo, dateFrom: dateFrom }
      Promise.all([this.$store.dispatch('getFullEmpl', toSend), this.$store.dispatch('getVacList', toSend)]).then(() => {
        if (this.VacEmployees.length == 1) {
          this.selectedEmp[0] = this.VacEmployees[0]
        }
        this.tableLoading = false
        if (routeCheck.accept) {
          for (let i = 0; i < this.VacDate.length; i++) {
            console.log('i', i)
            console.log('this.VacDate', this.VacDate[i].StafferVacationID)
            console.log('routeCheck.accept', routeCheck.accept)
            console.log('12', this.VacDate[i].StafferVacationID == routeCheck.accept)
            if (this.VacDate[i].StafferVacationID == routeCheck.accept) {
              if (this.VacDate[i].State == 1) {
                this.datesAction(this.VacDate[i], 'submit')
              } else {
                this.datesAction(this.VacDate[i], 'approve')
              }
            }
          }
        }
        if (routeCheck.reject) {
          for (let i = 0; i < this.VacDate.length; i++) {
            if (this.VacDate[i].StafferVacationID == routeCheck.reject) {
              this.datesAction(this.VacDate[i], 'dismiss')
            }
          }
        }
      }).catch((error) => {
        this.tableLoading = false
        // console.log('error: ' + error)
        this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'warning', direction: 'top center' })
      })
    },
    mounted () {
      const d = {}
      document.addEventListener('mousedown', e => {
        const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
        if (e.button === 0 && closestDialog != null && e.target.classList.contains('v-card__title')) { // element which can be used to move element
          d.el = closestDialog // element which should be moved
          d.mouseStartX = e.clientX
          d.mouseStartY = e.clientY
          d.elStartX = d.el.getBoundingClientRect().left
          d.elStartY = d.el.getBoundingClientRect().top
          d.el.style.position = 'fixed'
          d.el.style.margin = 0
          d.oldTransition = d.el.style.transition
          d.el.style.transition = 'none'
        }
      })
      document.addEventListener('mousemove', e => {
        if (d.el === undefined) return
        d.el.style.left = Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + 'px'
        d.el.style.top = Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + 'px'
      })
      document.addEventListener('mouseup', () => {
        if (d.el === undefined) return
        d.el.style.transition = d.oldTransition
        d.el = undefined
      })
      setInterval(() => { // prevent out of bounds
        const dialog = document.querySelector('.v-dialog.v-dialog--active')
        if (dialog === null) return
        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + 'px'
        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + 'px'
      }, 100)
    },
    methods: {
      clearFilter () {
        this.StatusFilter = { status: null, text: '' }
        this.EmpFilter = {}
        this.DirectionFilter = {}
      },
      update (value) {
        // console.log(value)
        let toSend = {}
        if (value.range) {
          if (value.from > value.to) {
            toSend = { dateFrom: value.to + '-1-1', dateTo: value.from + '-12-31' }
          } else {
            toSend = { dateFrom: value.from + '-1-1', dateTo: value.to + '-12-31' }
          }
        } else {
          toSend = { dateFrom: value.from + '-1-1', dateTo: value.from + '-12-31' }
        }
        toSend.update = true
        this.tableLoading = true
        this.$store.dispatch('getFullEmpl', toSend).then((resp) => {
          console.log(resp)
          if (resp.data.length == 1) {
            this.selectedEmp[0] = resp.data[0]
          }
          this.$store.dispatch('getVacList', toSend).then(() => {
            this.tableLoading = false
          }).catch((error) => {
            this.tableLoading = false
            this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'warning', direction: 'top center' })
          })
        }).catch((error) => {
          this.tableLoading = false
          // console.log('error: ' + error)
          this.$store.dispatch('setSnackbar', { state: true, text: error, color: 'warning', direction: 'top center' })
        })
      },
      statusCase (item) {
        switch (parseInt(item, 10)) {
          case 2:
            return { text: 'утвержден', color: 'primary', colorHex: '#3260b0' }
          case 1:
            return { text: 'согласован', color: 'secondary', colorHex: '#42e950' }
          case -1:
            return { text: 'отклонен', color: 'warning', colorHex: '#e43c4d' }
          default:
            return { text: '', color: '', colorHex: '#c7b5b5' }
        }
      },
      showButton (item, trigger) {
        switch (trigger) {
          case 'del':
            if (item.State < 1 && (item.CreatorID == this.empid || item.EmpID == this.empid)) return true
            return false
          case 'approve':
            if (item.State == 0 && item.CanAccept) return true
            return false
          case 'submit':
            if (item.State == 1 && item.CanApprove) return true
            return false
          case 'dismiss':
            if ((item.State == 0 && item.CanAccept) || (item.State == 1 && item.CanApprove)) return true
            return false
          case 'history':
            return false
          default:
            return true
        }
      },
      showedMonth (value) {
        return value[0] ? value[0] : ''
      },
      datesAction (value, trigger) {
        console.log('v :', value)
        console.log('t :', trigger)
        const days = value.DateTill - value.DateFrom + 1
        this.actionAgree.inputValue = ''
        let fd = ''
        let sd = ''
        switch (trigger) {
          case 'info':
            console.log('Info')
            console.log(value)
            break
          case 'edit':
            fd = value.DateFromS.split('.')
            fd = fd[2] + '-' + fd[1] + '-' + fd[0]
            sd = value.DateTillS.split('.')
            sd = sd[2] + '-' + sd[1] + '-' + sd[0]
            this.dates = [fd, sd]
            this.StafferVacationID = value.StafferVacationID
            this.addpopup = true
            console.log('Edit')
            break
          case 'del':
            this.actionAgree.show = true
            this.actionAgree.title = 'Вы хотите удалить отпуск?'
            this.actionAgree.body = value.EmpFIO + '  Отпуск: c ' + value.DateFromS + ' по ' + value.DateTillS + ' на: ' + days + ' ' + this.lastDigitToWord(days).toLowerCase()
            this.actionAgree.inputLabel = 'Причина'
            this.actionAgree.type = 'del'
            this.actionAgree.value = value
            break
          case 'approve':
            this.actionAgree.show = true
            this.actionAgree.title = 'Вы хотите согласовать отпуск?'
            this.actionAgree.body = value.EmpFIO + '  Отпуск: c ' + value.DateFromS + ' по ' + value.DateTillS + ' на: ' + days + ' ' + this.lastDigitToWord(days).toLowerCase()
            this.actionAgree.inputLabel = null
            this.actionAgree.type = 'approve'
            this.actionAgree.value = value
            break
          case 'submit':
            this.actionAgree.show = true
            this.actionAgree.title = 'Вы хотите утвердить отпуск?'
            this.actionAgree.body = value.EmpFIO + '  Отпуск: c ' + value.DateFromS + ' по ' + value.DateTillS + ' на: ' + days + ' ' + this.lastDigitToWord(days).toLowerCase()
            this.actionAgree.inputLabel = null
            this.actionAgree.type = 'submit'
            this.actionAgree.value = value
            break
          case 'dismiss':
            this.actionAgree.show = true
            this.actionAgree.title = 'Вы хотите отклонить отпуск?'
            this.actionAgree.body = value.EmpFIO + '  Отпуск: c ' + value.DateFromS + ' по ' + value.DateTillS + ' на: ' + days + ' ' + this.lastDigitToWord(days).toLowerCase()
            this.actionAgree.inputLabel = 'Причина'
            this.actionAgree.type = 'dismiss'
            this.actionAgree.value = value
            break
          case 'history':
            console.log('history')
            break
          default:
            console.log('default')
        }
      },
      actionAgreeSubmit  (value) {
        let toSend = {}
        this.actionAgree.loading = true
        switch (value.type) {
          case 'del':
            toSend = { vacationid: this.actionAgree.value.StafferVacationID, reason: this.actionAgree.inputValue, BaseIndex: this.actionAgree.value.BaseIndex }
            this.$store.dispatch('DeleteVacation', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, title: null, text: 'Отпуск удален', color: 'success', direction: 'top center' })
              this.actionAgree.loading = false
              this.actionAgree.show = false
            }).catch((e) => {
              this.$store.dispatch('setSnackbar', { state: true, title: e.Message, text: e.Information, color: 'warning', direction: 'top center' })
              this.actionAgree.loading = false
              console.log('error')
              console.log(e)
            })
            break
          case 'approve':
            console.log(this.actionAgree.value.StafferVacationID)
            toSend = { vacationid: this.actionAgree.value.StafferVacationID, newstatus: '1', reason: this.actionAgree.inputValue, BaseIndex: this.actionAgree.value.BaseIndex }
            this.$store.dispatch('ChangeVacationStatus', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, title: null, text: 'Отпуск согласован', color: 'success', direction: 'top center' })
              this.actionAgree.loading = false
              this.actionAgree.show = false
            }).catch((e) => {
              console.log(e)
              this.$store.dispatch('setSnackbar', { state: true, title: e.Message, text: e.Information, color: 'warning', direction: 'top center' })
              this.actionAgree.loading = false
              console.log('error')
              console.log(e)
            })
            break
          case 'submit':
            console.log(this.actionAgree.value.StafferVacationID)
            toSend = { vacationid: this.actionAgree.value.StafferVacationID, newstatus: '2', reason: this.actionAgree.inputValue, BaseIndex: this.actionAgree.value.BaseIndex }
            this.$store.dispatch('ChangeVacationStatus', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, title: null, text: 'Отпуск утвержден', color: 'success', direction: 'top center' })
              this.actionAgree.loading = false
              this.actionAgree.show = false
            }).catch((e) => {
              console.log(e)
              this.$store.dispatch('setSnackbar', { state: true, title: e.Message, text: e.Information, color: 'warning', direction: 'top center' })
              this.actionAgree.loading = false
              console.log('error')
              console.log(e)
            })
            break
          case 'dismiss':
            console.log(this.actionAgree.value.StafferVacationID)
            toSend = { vacationid: this.actionAgree.value.StafferVacationID, newstatus: '-1', reason: this.actionAgree.inputValue, BaseIndex: this.actionAgree.value.BaseIndex }
            this.$store.dispatch('ChangeVacationStatus', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, title: null, text: 'Отпуск отклонен', color: 'success', direction: 'top center' })
              this.actionAgree.loading = false
              this.actionAgree.show = false
            }).catch((e) => {
              console.log(e)
              this.$store.dispatch('setSnackbar', { state: true, title: e.Message, text: e.Information, color: 'warning', direction: 'top center' })
              this.actionAgree.loading = false
              console.log('error')
              console.log(e)
            })
            break
          case 'history':
            console.log('history')
            break
          default:
            console.log('default')
        }
      },
      lastDigitToWord (digit) {
        var lastFigure = parseInt(digit.toString().substr(digit.toString().length - 1, 1))
        if (digit > 11 && digit < 15) {
          return 'Дней'
        } else {
          if (lastFigure == 1) return 'День'
          if (lastFigure > 1 && lastFigure < 5) return 'Дня'
          if (lastFigure == 0 || lastFigure >= 5) return 'Дней'
        }
      },
      dateDM (item) {
        var firstDatetime = new Date(item[0])
        var secondDatetime = new Date(item[1])
        if (firstDatetime > secondDatetime) {
          if (firstDatetime.getMonth() == secondDatetime.getMonth()) {
            return 'c ' + secondDatetime.getDate() + ' по ' + firstDatetime.getDate() + ' ' + this.monthNames[firstDatetime.getMonth()]
          } else {
            return 'c ' + secondDatetime.getDate() + ' ' + this.monthNames[secondDatetime.getMonth()] + ' по ' + firstDatetime.getDate() + ' ' + this.monthNames[firstDatetime.getMonth()]
          }
        } else {
          if (firstDatetime.getMonth() == secondDatetime.getMonth()) {
            return 'c ' + firstDatetime.getDate() + ' по ' + secondDatetime.getDate() + ' ' + this.monthNames[firstDatetime.getMonth()]
          } else {
            return 'c ' + firstDatetime.getDate() + ' ' + this.monthNames[firstDatetime.getMonth()] + ' по ' + secondDatetime.getDate() + ' ' + this.monthNames[secondDatetime.getMonth()]
          }
        }
      },
      openAdd () {
        if (this.VacEmployees.length > 1) {
          this.selectedEmp = []
        }
        this.dates = []
        this.StafferVacationID = null
        this.addpopup = true
      },
      dateMinusText (item) {
        let day = this.dateMinus(item)
        day = this.lastDigitToWord(day)
        return day
      },
      dateMinus (item) {
        var date1 = new Date(item[0])
        var date2 = new Date(item[1])
        const diffTime = Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
        return diffDays
      },
      close () {
        if (this.VacEmployees.length > 1) {
          this.selectedEmp = []
        }
        this.addpopup = false
      },
      saveVac () {
        this.loadingBtn = true
        if (this.VacEmployees.length == 1) {
          this.selectedEmp[0] = this.VacEmployees[0]
        }
        if (this.StafferVacationID) {
        } else {
          var toSend = {}
          var firstDatetime = new Date(this.dates[0])
          var secondDatetime = new Date(this.dates[1])
          if (firstDatetime > secondDatetime) {
            toSend = { vacempid: this.selectedEmp[0].empid, dateTo: this.dates[0], dateFrom: this.dates[1] }
          } else {
            toSend = { vacempid: this.selectedEmp[0].empid, dateTo: this.dates[1], dateFrom: this.dates[0] }
          }
          this.$store.dispatch('AddVacation', toSend).then((i) => {
            this.addpopup = false
            this.loadingBtn = false
            this.$store.dispatch('setSnackbar', { state: true, text: 'Отпуск добавлен', color: 'success', direction: 'top center' })
          }).catch((e) => {
            console.log(e)
            this.$store.dispatch('setSnackbar', { state: true, title: e.Message, text: e.Information, color: 'error', direction: 'top center' })
            this.loadingBtn = false
            this.addpopup = false
          })
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      formatDate2 (date) {
        if (!date) return null

        const [year, month] = date.split('-')
        return `${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('.')
        return new Date(year + '-' + month + '-' + day).getTime()
      },
    },
  }
</script>
<style>
  .spaceout{
   border:0;
  }
  .anima{
    -webkit-transition: width 2s, height 4s; /* For Safari 3.1 to 6.0 */
    transition: width 2s, height 4s;
  }

  .v-data-table__selected .v-data-table__mobile-row{
    background-color: #52f599;
  }
  .emps .v-data-table__selected{
    background-color: #52f599;
  }
</style>
